.fiber_bg {
    background:
        linear-gradient(27deg, var(--fiber-color-one) 5px, transparent 5px) 0 5px,
        linear-gradient(207deg, var(--fiber-color-one) 5px, transparent 5px) 10px 0px,
        linear-gradient(27deg, var(--fiber-color-two) 5px, transparent 5px) 0px 10px,
        linear-gradient(207deg, var(--fiber-color-one) 5px, transparent 5px) 10px 5px,
        linear-gradient(90deg, var(--fiber-color-three) 10px, transparent 10px),
        linear-gradient(var(--fiber-color-four) 25%, var(--fiber-color-five) 25%,
        var(--fiber-color-five) 50%, transparent 50%, transparent 75%,
        var(--fiber-color-six) 75%, var(--fiber-color-six));
    background-size: 20px 20px;
    min-height: 100vh;
}

.dashboard {
    margin: 10px;
    margin-bottom: 0px;
    display: grid;
    width: calc(100vw - 20px);
    gap: 10px;
    grid-template-columns: auto auto auto;
}

@media all and (min-width: 800px) and (max-width: 1200px) {
    .dashboard {
        grid-template-columns: auto auto;
    }   
}

@media all and (max-width: 800px) {
    .dashboard {
        grid-template-columns: auto;
    }
}