.info_card {
    position: relative;
    background-color: var(--widget-color);
    box-shadow: rgba(5, 0, 24, 0.2) 0px 5px 16px;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 10px;
    min-width: 30vw;
    max-height: 44vh;
    resize: vertical;
    overflow: scroll;
    transition: all 0.2s ease-out;
}

.scroll_indicator {
    position: fixed;
    width: 12px;
    height: 12px;
    background: var(--primary-color);
    clip-path: polygon(50% 100%, 0 0, 100% 0);
    transition: opacity 0.2s ease-out;
}

@media all and (max-width: 800px) {
    .info_card {
        max-height: 50vh;
    }
}

.link {
    color: var(--font-primary-color);
}

.link:hover {
    color: var(--primary-color);
}

.field_title {
    font-size: 18px;
}

.field_right {
    float: right;
}