canvas {
    width: 100vw;
    height: 100vh;
    image-rendering: pixelated;
}

.big_button {
    padding: 12px;
    border: transparent;
    border: 1px solid var(--primary-color);
    background-color: var(--bg-color);
    color: var(--primary-color);
    font-size: 25px;
    font-family: "Roboto";
    transition: all 0.2s ease-out;
}

.big_button:hover {
    background-color: var(--primary-color);
    color: var(--font-secondary-color);
}

.typing_container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.input_cursor_h1 {
    display: inline-block;
    width: 2px;
    height: 90px;
    background-color: var(--font-primary-color);
    margin-left: 8px;
    animation: blink .5s ease-out infinite alternate;
}

.input_cursor_h2 {
    display: inline-block;
    width: 2px;
    height: 60px;
    background-color: var(--font-primary-color);
    margin-left: 8px;
    animation: blink .5s ease-out infinite alternate;
}

@keyframes blink {
    0% {
        opacity: 1;
    }

    40% {
        opacity: 1;
    }

    60% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}