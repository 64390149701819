::-moz-selection {
    background: var(--selection-color);
}

::-webkit-selection {
    background: var(--selection-color);
}

::selection {
    background: var(--selection-color);
}

html,body {
    text-size-adjust: none;
    -webkit-text-size-adjust: none;
    -ms-text-size-adjust: none;
    -moz-text-size-adjust: none;
}

body {
    margin: 0;
    font-family: var(--primary-font);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--bg-color);
    color: var(--font-primary-color);
    scrollbar-color: var(--widget-mask-color) transparent;
}

h1 {
    font-size: 80px;
    font-weight: 500;
    margin: 0;
}

h2 {
    font-size: 60px;
    font-weight: 500;
    margin: 0;
}

h3 {
    font-size: 50px;
    font-weight: 500;
    margin: 0;
}

h4 {
    font-size: 40px;
    font-weight: 500;
    margin: 0;
}

h5 {
    font-size: 35px;
    font-weight: 500;
    margin: 0;
}

h6 {
    font-size: 30px;
    font-weight: 500;
    margin: 0;
}

@media all and (min-width: 800px) and (max-width: 1200px) {
    h1 {
        font-size: 70px;
        font-weight: 600;
        margin: 0;
    }

    h2 {
        font-size: 55px;
        font-weight: 600;
        margin: 0;
    }

    h3 {
        font-size: 45px;
        font-weight: 600;
        margin: 0;
    }

    h4 {
        font-size: 38px;
        font-weight: 600;
        margin: 0;
    }

    h5 {
        font-size: 32px;
        font-weight: 600;
        margin: 0;
    }

    h6 {
        font-size: 30px;
        font-weight: 600;
        margin: 0;
    }
}

@media all and (max-width: 800px) {
    h1 {
        font-size: 50px;
        font-weight: 600;
        margin: 0;
    }

    h2 {
        font-size: 30px;
        font-weight: 600;
        margin: 0;
    }

    h3 {
        font-size: 28px;
        font-weight: 600;
        margin: 0;
    }

    h4 {
        font-size: 26px;
        font-weight: 600;
        margin: 0;
    }

    h5 {
        font-size: 24px;
        font-weight: 600;
        margin: 0;
    }

    h6 {
        font-size: 22px;
        font-weight: 600;
        margin: 0;
    }
}


p {
    font-size: 14px;
}

b {
    font-weight: 600;
}

a {
    text-decoration: none;
    color: inherit
}

hr {
    color: var(--primary-color);
    border-top: 1px solid;
    border-bottom: none;
    border-left: none;
    border-right: none;
}

table {
    border-collapse: collapse;
    text-align: left;
    width: 100%;
}

tr {
    font-size: 14px;
    border-bottom: 1px solid var(--primary-color);
}

th {
    font-size: 14px;
    font-weight: 600;
}

td {
    padding-top: 12px;
    padding-bottom: 2px;
}

.monospace {
    font-family: "Noto Sans Mono";
    font-weight: 450;
}

.number {
    color: var(--number-color);
}

img {
    border-radius: 4px;
}

label {
    font-size: 15px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}