.navbar {
    position: relative;
    top: 0;
    left: 0;
    background-color: var(--widget-color);
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.3);
    transition: all 0.2s ease-out;
    z-index: 10;
}

.button {
    padding: 10px;
    border: transparent;
    border-radius: 0px;
    background-color: transparent;
    color: var(--font-color);
    font-size: 18px;
}

.button:hover {
    background-color: var(--widget-mask-color);
    transition: all 0.2s ease-out;
}
